import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects'

import { confirmationCodeLoadingDuck } from 'src/reducers/loginForm'

import { setTokens } from 'services/access_tokens'
import { EMAIL_CONTACT_METHOD } from 'services/contact_methods'
import { buildUser } from 'services/user_helpers'
import { uploadToS3ByPresignedPost } from 'src/services/s3_upload_client'
import { uploadFileToS3Worker } from './s3'

import { EVENT_SUBSCRIBE_ERROR, EVENT_SUBSCRIBE_SUCCESS, setUserId } from 'src/actions'

import {
  createUser,
  fetchCurrentUser,
  fetchUserGQ,
  UPDATE_AVATAR,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  FETCH_CURRENT_USER_SUCCESS
} from 'src/actions/user'

import { showHud, updateMenuPanel } from 'src/actions/frontend'

import {
  HIDE_LOGIN,
  hideLogin,
  LOGIN_COMPLETE,
  loginComplete,
  REQUEST_SUBMIT_LOGIN,
  REQUIRE_LOGIN,
  showLogin,
  SUBMIT_LOGIN_CONFIRMATION_SUCCESS,
  SUBMIT_LOGIN_ERROR,
  submitLogin,
  updateLoginForm,
  resetLoginForm
} from 'src/actions/login'

import { fetchMedia } from 'src/actions/media'

import {
  FETCH_INVITATION_ERROR,
  FETCH_INVITATION_SUCCESS,
  fetchInvitation,
  setInvitationId
} from 'src/actions/invitations'

import { DESTINATION_TYPES_CONSTANTS } from 'src/constants'

import {
  contactMethodSelector,
  currentUsersInvitationSelector,
  eventIdSelector,
  eventSelector,
  isLoggedInSelector,
  isSubscribedSelector,
  loginFormSelector,
  userIdSelector,
  destinationTypeSelector,
  userSelector
} from 'src/selectors'
import { androidObject, isHobnobAndroidClient } from 'src/services/utils'

import { putAll } from './helpers'

import { paymentMethodsListRequest, ticketTypesCountsLoadedReset } from '../actions/paymentGraphql'

export default function * loginRootSaga() {
  yield fork(watchLoginConfirmationSuccessSaga)
  yield fork(watchRequireLoginSaga)
  yield fork(watchLoginCompleteSaga)
  yield fork(watchUpdateAvatarSaga)
  yield takeEvery(REQUEST_SUBMIT_LOGIN, requestSubmitLoginWorker)
}

function * watchUpdateAvatarSaga() {
  while (true) {
    const { file, onSuccessCallback } = yield take(UPDATE_AVATAR)
    const user = yield select(userSelector)
    const request = uploadToS3ByPresignedPost(
      user.avatar_presigned_post,
      file,
      user.avatar_redirect_url
    )

    yield fork(uploadFileToS3Worker, request, null, [], [], onSuccessCallback)
  }
}

function * watchLoginCompleteSaga() {
  while (true) {
    yield take(LOGIN_COMPLETE)
    yield put(hideLogin())
    yield put(updateMenuPanel('profile'))
    yield put(resetLoginForm())
  }
}

function * watchRequireLoginSaga() {
  while (true) {
    const action = yield take(REQUIRE_LOGIN)
    let isSubscribed = yield select(isSubscribedSelector)
    let isLoggedIn = yield select(isLoggedInSelector)

    if (!isSubscribed || !isLoggedIn) {
      yield put(showLogin(action))
      const otherAction = yield take([HIDE_LOGIN, LOGIN_COMPLETE])
      if (otherAction.type === HIDE_LOGIN && otherAction.canceled) {
        continue
      }

      isSubscribed = yield select(isSubscribedSelector)
      isLoggedIn = yield select(isLoggedInSelector)
      if (!isSubscribed || !isLoggedIn) {
        const subscribeAction = yield take([
          EVENT_SUBSCRIBE_ERROR,
          EVENT_SUBSCRIBE_SUCCESS,
          HIDE_LOGIN
        ])
        if (
          subscribeAction.type === EVENT_SUBSCRIBE_ERROR ||
          (subscribeAction.type === HIDE_LOGIN && subscribeAction.canceled)
        ) {
          continue
        }
      }
    }

    if (typeof action.nextActionSuccess === 'function') {
      const userId = yield select(userIdSelector)
      const successActions = action.nextActionSuccess(userId)
      yield putAll(successActions)
    } else {
      yield putAll(action.nextActionSuccess)
    }
    yield put(hideLogin())
  }
}

function * requestSubmitLoginWorker({ username, contactMethodType }) {
  yield put(confirmationCodeLoadingDuck.actions.loading())
  // First create the user
  if (yield call(createUserWorker)) {
    yield put(submitLogin(username, contactMethodType))
    // We don't ever expect this to be "successful" on the first try
    const loginErrorAction = yield take(SUBMIT_LOGIN_ERROR)
    yield call(submitLoginErrorWorker, loginErrorAction)
  }
  yield put(confirmationCodeLoadingDuck.actions.done())
}

// Return true after the user has been successfully created (or if the user does not need to be created)
function * createUserWorker() {
  const loginForm = yield select(loginFormSelector)
  const contactMethod = yield select(contactMethodSelector)
  const skipCreateUser =
    contactMethod && contactMethod.type === EMAIL_CONTACT_METHOD && loginForm.useMaskedEmail
  if (skipCreateUser) return true

  const user = buildUser(loginForm, contactMethod)
  yield put(createUser(user))

  const action = yield take([CREATE_USER_SUCCESS, CREATE_USER_ERROR])
  if (action.type === CREATE_USER_SUCCESS) {
    return true
  }

  const errors = action.response.result.errors
  if (errors.phone_number && errors.phone_number[0] === 'has already been taken') {
    return true
  } else if (
    errors['primary_contact_method.phone_number'] &&
    errors['primary_contact_method.phone_number'][0] === 'is an invalid number'
  ) {
    yield put(showHud('error', 'Sorry, you entered an invalid phone number'))
  } else {
    yield put(showHud('error', 'Unable to send login. Please retry in a few moments.'))
  }
}

function * submitLoginErrorWorker(action) {
  if (action.response.error === 'otp_required') {
    // This is actually the start of a "successful" login
    // yield put(confirmationCodeLoadingDuck.actions.done())
    yield put(updateLoginForm({ loginStep: 'confirmationCode' }))
  } else if (action.response.error === 'contact_method_blocked') {
    yield put(
      showHud(
        'error',
        'We were unable to send you a code. Please text START to 462662 and try again'
      )
    )
    yield put(updateLoginForm({ loginStep: 'contactMethod' }))
  } else if (action.response.error === 'user_banned') {
    yield put(showHud('error', action.response.error_description))
    yield put(updateLoginForm({ loginStep: 'contactMethod' }))
  } else {
    yield put(
      showHud('error', 'Unable to send login. Please retry in a few moments.')
    )
  }
}

// TODO: Ideally this would be brought into requestSubmitLoginWorker or a new requestSubmitLoginConfirmationWorker
function * watchLoginConfirmationSuccessSaga() {
  while (true) {
    const { response } = yield take(SUBMIT_LOGIN_CONFIRMATION_SUCCESS)
    // Reset ticket types count loading status because it needs to be loaded
    // again (because it is specific to the invitation, which changes when a
    // user logs in)
    if (isHobnobAndroidClient()) {
      androidObject().parseAuthResponseAndStoreTokens(JSON.stringify(response))
    }

    setTokens(response)
    const eventId = yield select(eventIdSelector)
    const destinationType = yield select(destinationTypeSelector)

    const { newUserId, loginInRSVP } = yield select(loginFormSelector)

    if (newUserId) {
      yield put(setUserId(newUserId))
    }
    yield put(fetchCurrentUser())
    yield put(fetchUserGQ())
    // TODO: Is this actually required
    yield take(FETCH_CURRENT_USER_SUCCESS)

    if (eventId) {
      yield put(ticketTypesCountsLoadedReset())
      yield fork(getUserInvitationSaga)
      if (destinationType === DESTINATION_TYPES_CONSTANTS.event) {
        yield put(fetchMedia(eventId))
        yield put(paymentMethodsListRequest())
      }
    }

    if (!newUserId) {
      yield put(updateLoginForm({
        fullNameTitle: 'Confirm Your User Name'
      }))
    }

    if (loginInRSVP) {
      yield put(loginComplete())
    } else {
      yield put(updateLoginForm({ loginStep: 'fullName' }))
    }
  }
}

// Check if the user already has an Invitation
// If they do set the invitation id
// NOTE: We need to fetch the invitation to retrieve the token
function * getUserInvitationSaga() {
  const invitation = yield select(currentUsersInvitationSelector)

  if (invitation && !invitation.destroyed_at) {
    const event = yield select(eventSelector)
    yield put(fetchInvitation(invitation.id, event.id))
    const action = yield take([
      FETCH_INVITATION_SUCCESS,
      FETCH_INVITATION_ERROR
    ])
    if (action.type === FETCH_INVITATION_SUCCESS) {
      yield put(setInvitationId(invitation.id))
    }
  }
}
